import { Chip } from '@mui/material';
import { StyleSheet, css } from 'aphrodite';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserDataHook } from '../../core/hooks/useUserData';
import useEscapeKey from '../hooks/useEscapeKey';
import { publicFetch } from '../utils/apiUtils';
import { ImageCardProps } from './ImageCard';
import UserLine from './UserLine';

interface CommentProps {
  id: string;
  content: string;
  creator: {
    username: string;
    profilePicS3Url: string;
  };
  likedByAggregate: {
    count: number;
  };
}

interface ImagePopupProps extends ImageCardProps {
  comments: CommentProps[];
}

const ImagePopup = (props: { userDataHook: UserDataHook }) => {
  const { imageId } = useParams();
  const [imagePopupProps, setImagePopupProps] = useState<ImagePopupProps>();

  const navigate = useNavigate();
  const location = useLocation();

  // Define how we get out of image popup modal (by going off this id page)
  const exitFromPopup = () => {
    const pathArr = _.compact(location.pathname.split('/'));
    const newPath =
      _.size(pathArr) <= 1 ? '' : _.join(_.slice(pathArr, 0, -1), '/');

    navigate('/' + newPath);
  };

  // Handle escape key press to exit
  useEscapeKey(exitFromPopup);

  useEffect(() => {
    publicFetch(`images/full/${imageId}`).then((res) => {
      setImagePopupProps(res.data);
    });
  }, [imageId]);

  return (
    <>
      <div
        className={css(styles.blurredBackground)}
        onClick={() => exitFromPopup()}
      ></div>
      {imagePopupProps && (
        <div className={css(styles.fullOverlay)}>
          {/* TODO: Render image */}
          <div className={css(styles.imageContainer)}>
            <img
              src={imagePopupProps.s3Url}
              alt={imagePopupProps.prompt}
              className={css(styles.image)}
            />
          </div>
          <div className={css(styles.commentsContainer)}>
            <div className={css(styles.topImageInfoContainer)}>
              <div className={css(styles.title)}>
                {imagePopupProps.title ?? 'Untitled'}
              </div>
              <div className={css(styles.prompt)}>
                <u>Prompt</u>
                {': '}
                {imagePopupProps.prompt}
              </div>
              {/* TODO: Add a "Create Your Own!" button */}
              <div className={css(styles.tagsContainer)}>
                <u className={css(styles.tagText)}>Tags: </u>
                {_.map(imagePopupProps.tags, (tag, idx) => (
                  <Chip
                    label={tag.name}
                    key={idx}
                    onClick={() => navigate(`/explore/${tag.name}`)}
                  />
                ))}
              </div>
              <div className={css(styles.userLine)}>
                <UserLine
                  username={imagePopupProps.creator.username}
                  profilePicS3Url={imagePopupProps.creator.profilePicS3Url}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  blurredBackground: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    right: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    overscrollBehavior: 'contain',
  },
  fullOverlay: {
    backgroundColor: 'white',
    borderRadius: '4px',
    position: 'fixed',
    // maxWidth: '80%',
    // minWidth: 'fit-content',
    width: 'fit-content',
    height: '80%',
    top: '10%',
    right: 0,
    left: 0,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overscrollBehavior: 'contain',
  },
  imageContainer: {
    minWidth: '512px',
    maxWidth: '100%',
    height: '100%',
    padding: 0,
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  commentsContainer: {
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    padding: '8px',
  },
  topImageInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    paddingBottom: '8px',
    paddingTop: '16px',
  },
  prompt: {
    fontSize: 18,
    padding: '8px',
    borderWidth: '1px',
    borderColor: 'gray',
    borderStyle: 'solid',
    borderRadius: '4px',
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '8px',
    alignItems: 'center',
  },
  tagText: {
    fontSize: 18,
    paddingRight: '8px',
  },
  userLine: {
    paddingTop: '12px',
  },
});

export default ImagePopup;
