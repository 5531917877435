import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Layout from './core/components/Layout';
import LandingPage from './landing/components/LandingPage';
import CreatePage from './create/components/CreatePage';
import AuthRedirect from './login/components/AuthRedirect';
import NoPage from './core/components/NoPage';
import Login from './login/components/Login';
import { useUserData } from './core/hooks/useUserData';
import TagPage from './tag/components/TagPage';
import ImagePopup from './core/components/ImagePopup';
import UserPage from './user/components/UserPage';

function App() {
  const userDataHook = useUserData();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout userDataHook={userDataHook} />}>
          {/* ----------------- Landing Explore Page ----------------- */}
          <Route path="" element={<LandingPage userDataHook={userDataHook} />}>
            {/* Encode image popup in url so sharing an image also shares the tag */}
            <Route
              path=":imageId"
              element={<ImagePopup userDataHook={userDataHook} />}
            />
          </Route>
          {/* ----------------- Explore Tag Page ----------------- */}
          <Route
            path="explore/:tagName/"
            element={<TagPage userDataHook={userDataHook} />}
          >
            {/* Encode image popup in url so sharing an image also shares the tag */}
            <Route
              path=":imageId"
              element={<ImagePopup userDataHook={userDataHook} />}
            />
          </Route>

          {/* ----------------- Create Page ----------------- */}
          <Route
            path="create"
            element={
              userDataHook.isLoggedIn ? (
                <CreatePage userDataHook={userDataHook} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          {/* ----------------- User Page ----------------- */}
          <Route
            path="user/:username"
            element={<UserPage userDataHook={userDataHook} />}
          >
            {/* Encode image popup in url so sharing an image also shares the tag */}
            <Route
              path=":imageId"
              element={<ImagePopup userDataHook={userDataHook} />}
            />
          </Route>

          {/* ----------------- 404 Page ----------------- */}
          <Route path="*" element={<NoPage />} />
        </Route>

        {/* ----------------- Login / Auth Routes ----------------- */}
        {/* /login reroutes to Cognito login page */}
        <Route path="/login" element={<Login />} />
        {/* /auth is only used for retrieving the token from URL then redirecting */}
        <Route
          path="/auth/*"
          element={<AuthRedirect userDataHook={userDataHook} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
