import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserDataHook } from '../../core/hooks/useUserData';
const domain =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? '.promptr.art'
    : 'localhost';

const AuthRedirect = (props: { userDataHook: UserDataHook }) => {
  // This is all to pull the code out of the query string in the URL
  const location = useLocation();
  const urlQuery = useMemo(() => {
    return new URLSearchParams(location.hash);
  }, [location.hash]);

  const navigate = useNavigate();

  // On mount add tokens to cookies and redirect
  useEffect(() => {
    const id_token = urlQuery.get('#id_token') || undefined;
    const access_token = urlQuery.get('access_token') || undefined;

    id_token &&
      props.userDataHook.setCookie('promptr_id_token', id_token, {
        domain,
      });
    access_token &&
      props.userDataHook.setCookie('promptr_access_token', access_token, {
        domain,
      });

    // TODO: Change this redirect to be something passed in
    props.userDataHook.checkLoggedIn().then(() => navigate('/create'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQuery]); // Only run this when code is updated

  return <></>;
};

export default AuthRedirect;
