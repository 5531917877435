import { Outlet } from 'react-router-dom';

import { UserDataHook } from '../hooks/useUserData';
import NavBar from './NavBar';

const Layout = (props: { userDataHook: UserDataHook }) => {
  /* TODO: Popup modal to fill in user info if logged in but info isn't filled in yet */

  return (
    <>
      <NavBar userDataHook={props.userDataHook} />
      <Outlet />
    </>
  );
};

export default Layout;
