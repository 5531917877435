import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { authenticatedFetch } from '../utils/apiUtils';

interface UserInfo {
  id: string;
  username: string;
  profilePicUrl: string;
  credits: number;
  bio?: string;
}

export function useUserData(): UserDataHook {
  const [cookies, setCookie, removeCookie] = useCookies([
    'promptr_id_token',
    'promptr_access_token',
  ]);

  // This will be replaced after the first render cycle, but is required to be true in order
  // to avoid always forcing not logged in behavior before we can check
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);

  const [userInfo, setUserInfo] = useState<UserInfo>();
  const setCredits = (credits: number) => {
    userInfo && setUserInfo({ ...userInfo, credits });
  };

  const checkLoggedIn = async () => {
    try {
      const newUserInfo = await authenticatedFetch('user/info');
      setIsLoggedIn(true);
      setUserInfo(newUserInfo.data);
    } catch (e) {
      setIsLoggedIn(false);
      setUserInfo(undefined);
    }
  };

  useEffect(() => {
    checkLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.promptr_access_token, cookies.promptr_id_token]); // This is implicitly used by authenticatedFetch, which is why React doesn't find it as a valid dep

  return {
    userInfo,
    cookies,
    setCookie,
    removeCookie,
    isLoggedIn,
    checkLoggedIn,
    setCredits,
  };
}

export interface UserDataHook {
  userInfo?: UserInfo;
  isLoggedIn: boolean;
  cookies: Record<string, string>;
  setCookie: (
    name: 'promptr_id_token' | 'promptr_access_token',
    value: any,
    options: any
  ) => void;
  removeCookie: (name: 'promptr_id_token' | 'promptr_access_token') => void;
  checkLoggedIn: () => Promise<void>;
  setCredits: (credits: number) => void;
}
