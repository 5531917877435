import { StyleSheet, css } from 'aphrodite';
import { useNavigate } from 'react-router-dom';

const UserLine = ({
  username,
  profilePicS3Url,
}: {
  username: string;
  profilePicS3Url: string;
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/user/${username}`)}
      className={css(styles.usernameLine)}
    >
      <button className={css(styles.userProfileButton)}>
        <img
          className={css(styles.userProfilePic)}
          src={profilePicS3Url}
          alt={username}
        />
      </button>
      <span className={css(styles.username)}>{username}</span>
    </div>
  );
};

const styles = StyleSheet.create({
  usernameLine: {
    // No wrap
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  userProfileButton: {
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'box-shadow 0.3s',
    ':hover': {
      boxShadow: '0 2px 4px rgba(0,0,0,0.18)',
    },
    marginRight: '6px',
  },
  userProfilePic: {
    width: '22px',
    height: '22px',
    borderRadius: '11px',
    cursor: 'pointer',
  },
  username: {
    textDecoration: 'underline',
    fontSize: '14px',
  },
});

export default UserLine;
