// Need to import like this to get types correctly
const axios = require('axios').default;

const URL =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://api.promptr.art'
    : 'http://localhost:8080';

export async function authenticatedFetch(apiPath: string) {
  return axios({
    method: 'get',
    url: `${URL}/api/secure/${apiPath}`,
    withCredentials: true,
    crossDomain: true,
  });
}

export async function authenticatedPost(apiPath: string, body: any) {
  return axios.post(`${URL}/api/secure/${apiPath}`, body, {
    withCredentials: true,
    crossDomain: true,
  });
}

export async function publicFetch(apiPath: string) {
  return axios({
    method: 'get',
    url: `${URL}/api/public/${apiPath}`,
    // This is just to send cookies if they exist, they're not required on these routes
    withCredentials: true,
    crossDomain: true,
  });
}

export async function publicPost(apiPath: string, body: any) {
  return axios.post(`${URL}/api/public/${apiPath}`, body, {
    // This is just to send cookies if they exist, they're not required on these routes
    withCredentials: true,
    crossDomain: true,
  });
}
