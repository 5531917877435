import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Button from '@mui/material/Button';

import PromptTextBox from './PromptTextBox';
import { UserDataHook } from '../../core/hooks/useUserData';
import { authenticatedPost, publicFetch } from '../../core/utils/apiUtils';
import _ from 'lodash';
import { ThreeDots } from 'react-loading-icons';
import ImageGallery from '../../core/components/ImagesGallery';
import { ImageCardProps } from '../../core/components/ImageCard';
import ImageInputFields from './ImageInputFields';

const CreatePage = (props: { userDataHook: UserDataHook }) => {
  const [text, setText] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // Currently loaded image info (being edited)
  const [imageInfos, setImagesInfos] = React.useState<ImageCardProps[]>();
  // Used to cache your recent so they update live without having to re-request
  const [prevImageInfos, setPrevImageInfos] = React.useState<ImageCardProps[]>(
    []
  );

  // When clicking an image from the gallery, fetch the info for it
  const clickImage = (imageId: string) => {
    // TODO: Only do this if current ids not in the set of prevImageInfos ids
    // imageInfos && setPrevImageInfos([...imageInfos, ...prevImageInfos]);
    publicFetch(`/images/full/${imageId}`).then((res) => {
      setImagesInfos([res.data]);
    });
  };

  const clickGenerateButton = async () => {
    const prompt = text;

    // Don't send an empty prompt
    if (_.isEmpty(prompt)) {
      return;
    }

    setIsLoading(true);
    imageInfos &&
      !_.isEmpty(imageInfos) &&
      setPrevImageInfos([...imageInfos, ...prevImageInfos]);
    setImagesInfos(undefined);

    try {
      const createdImagesResponse = await authenticatedPost('run_prompt', {
        prompt,
      });
      setImagesInfos(createdImagesResponse.data.images);
      props.userDataHook.setCredits(createdImagesResponse.data.creditsLeft);
    } catch (e) {
      // TODO: Show error
      console.log(e);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <div className={css(styles.pageContainer)}>
        {/* Input side */}
        <div className={css(styles.createSideContainer)}>
          <div className={css(styles.textParagraph)}>
            Submit a prompt below to our AI and we will generate an image from
            it! Each image costs 1 credit. If you run out credits, ask Connor to
            give you more.
          </div>
          <div className={css(styles.textParagraph)}>
            The image generated from your prompt will be different each time
            (even with the same prompt), so try out the same prompt multiple
            times to get a sense for what's being understoon in your prompt. If
            you're not sure what to put in your prompt, checkout out what others
            are generating and start with their prompt!
          </div>
          <div className={css(styles.promptBoxContainer)}>
            <div className={css(styles.promptBox)}>
              <div className={css(styles.inputFieldTitle)}>Prompt:</div>
              <PromptTextBox
                text={text}
                onChangeText={setText}
                isLoading={isLoading}
              />
              <div className={css(styles.inputFieldTitle)}>
                Credits left:{' '}
                {Math.trunc(props.userDataHook.userInfo?.credits ?? 0)}
              </div>
            </div>
          </div>
        </div>
        {/* Center button to generate */}
        <div className={css(styles.centerButtonContainer)}>
          <Button
            variant="contained"
            onClick={clickGenerateButton}
            className={css(styles.promptButton)}
            disabled={isLoading}
          >
            GENERATE
          </Button>
        </div>
        {/* Generated image side */}
        <div className={css(styles.displaySideContainer)}>
          {isLoading ? (
            <div className={css(styles.loadingContainer)}>
              <ThreeDots fill="#808080" className={css(styles.addPadding)} />
            </div>
          ) : (
            <>
              <div className={css(styles.imagesContainer)}>
                {imageInfos ? (
                  _.map(imageInfos, (imageInfo, idx) => (
                    <img
                      src={imageInfo.s3Url}
                      alt="generated by our AI"
                      key={idx}
                      className={css(styles.image)}
                    />
                  ))
                ) : (
                  <div className={css(styles.noImage)}></div>
                )}
              </div>
              {/* TODO: Make this work with a list of imageInfos */}
              {imageInfos && !_.isEmpty(imageInfos) && (
                <ImageInputFields
                  imageInfo={imageInfos[0]}
                  setImagesInfo={(newImageInfo) =>
                    setImagesInfos([newImageInfo])
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className={css(styles.bottomGallery)}>
        <h1>Recent:</h1>
        {props.userDataHook.userInfo?.id && (
          <ImageGallery
            sortedBy="newest"
            userId={props.userDataHook.userInfo?.id}
            initialImageProps={prevImageInfos}
            onClickOverride={clickImage}
          />
        )}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  pageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '10px',
    paddingTop: '36px',
  },
  createSideContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '44%',
  },
  centerButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '12%',
  },
  displaySideContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '44%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '512px',
  },
  textParagraph: {
    paddingTop: '4px',
    paddingBottom: '16px',
    paddingLeft: '8px',
  },
  inputFieldTitle: {
    fontSize: '16px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  promptBoxContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    flexDirection: 'column',
  },
  promptBox: {
    width: '85%',
  },
  addPadding: {
    padding: '24px',
  },
  promptButton: {
    padding: '12px',
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '512px',
  },
  image: {
    padding: '16px',
    maxWidth: '512px',
  },
  noImage: {
    width: '512px',
    height: '512px',
    backgroundColor: '#F6F6F6',
    borderStyle: 'dashed',
    borderWidth: '1px',
    borderColor: 'black',
  },
  bottomGallery: {
    padding: '10px',
  },
});

export default CreatePage;
