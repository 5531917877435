import React, { useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import TextField from '@mui/material/TextField';
import { ImageCardProps } from '../../core/components/ImageCard';
import { Button } from '@mui/material';
import _ from 'lodash';
import ImageTagInput from './ImageTagInput';
import { MAX_TITLE_LENGTH } from '../../constants';
import { authenticatedPost } from '../../core/utils/apiUtils';
import { MAX_TAG_LENGTH } from '../../constants';

interface ImageInputFieldsProps {
  imageInfo: ImageCardProps;
  setImagesInfo: (newImageInfo: ImageCardProps) => void;
}

const ImageInputFields: React.FC<ImageInputFieldsProps> = ({
  imageInfo,
  setImagesInfo,
}) => {
  // Deal with title state and saving
  const [titleInputText, setTitleInputText] = React.useState<string>(
    imageInfo.title ?? ''
  );
  const clickSaveTitle = async (title: string) => {
    if (title.length > MAX_TITLE_LENGTH) {
      alert(`Max title length is ${MAX_TITLE_LENGTH}`);
      return;
    }
    setImagesInfo({
      ...imageInfo,
      title,
    });
    await authenticatedPost(`images/info/${imageInfo.id}`, {
      title,
    });
  };

  // Deal with tag state and saving
  const [tags, _setTags] = React.useState<string[]>([]);
  // When changing images, set these tags to the new values
  useEffect(() => {
    const initialTags = imageInfo?.tags
      ? _.map(imageInfo.tags, (tag) => tag.name)
      : [];
    _setTags(initialTags);
  }, [imageInfo]);

  const setTags = async (newTags: string[]) => {
    if (_.some(newTags, (tag) => tag.length > MAX_TAG_LENGTH)) {
      alert(`Max tag length is ${MAX_TAG_LENGTH}`);
      return;
    }
    if (_.some(newTags, (tag) => _.includes(tag, ' '))) {
      alert(`Tags cannot include spaces, use "-" instead!`);
      return;
    }
    _setTags(newTags);
    // Update the image info in the createPage
    setImagesInfo({
      ...imageInfo,
      tags: _.map(newTags, (tag) => ({
        name: tag,
      })),
    });
    await authenticatedPost(`images/info/${imageInfo.id}`, {
      tags: newTags,
    });
  };

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.titleLine)}>
        <TextField
          className={css(styles.titleTextBox)}
          placeholder={imageInfo.title ?? 'Untitled'}
          value={titleInputText}
          onChange={(e) => setTitleInputText(e.target.value)}
          label={imageInfo.title ?? 'Untitled'}
        />
        <Button
          variant="contained"
          onClick={() => clickSaveTitle(titleInputText)}
          className={css(styles.button)}
        >
          Save Title
        </Button>
      </div>
      <div className={css(styles.tagLine)}>
        <div className={css(styles.tagsTextBox)}>
          <ImageTagInput tags={tags} setTags={setTags} />
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '512px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  titleLine: {
    width: '100%',
    display: 'flex',
  },
  titleTextBox: {
    width: '380px',
    paddingRight: '16px',
  },
  button: {},
  tagLine: {
    width: '100%',
    display: 'flex',
    paddingTop: '16px',
  },
  tagsTextBox: {
    width: '380px',
  },
});

export default ImageInputFields;
