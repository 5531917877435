import { StyleSheet, css } from 'aphrodite';
import { Outlet } from 'react-router-dom';
import ImageGallery from '../../core/components/ImagesGallery';
import { UserDataHook } from '../../core/hooks/useUserData';

const LandingPage = (props: { userDataHook: UserDataHook }) => {
  return (
    <>
      <div className={css(styles.centerTextContainer)}>
        <div className={css(styles.mainText)}>
          Promptr - AI Art Generation Platform
        </div>
      </div>
      <ImageGallery sortedBy="newest" />
      {/* This Outlet is used to render the image popup when in the subroute */}
      <Outlet />
    </>
  );
};

const styles = StyleSheet.create({
  centerTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '80px',
    flexDirection: 'column',
  },

  mainText: {
    fontSize: '56px',
    textAlign: 'center',
    padding: '16px',
  },
});

export default LandingPage;
