import { StyleSheet, css } from 'aphrodite';
import { Outlet, useParams } from 'react-router-dom';
import ImageGallery from '../../core/components/ImagesGallery';
import { UserDataHook } from '../../core/hooks/useUserData';

const TagPage = (props: { userDataHook: UserDataHook }) => {
  const { tagName } = useParams();

  return (
    <>
      <div className={css(styles.centerTextContainer)}>
        <div className={css(styles.mainText)}>{tagName}</div>
      </div>
      <ImageGallery sortedBy="newest" tag={tagName} />
      {/* This Outlet is used to render the image popup when in the subroute */}
      <Outlet />
    </>
  );
};

const styles = StyleSheet.create({
  centerTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px',
    flexDirection: 'column',
  },
  mainText: {
    fontSize: '56px',
    textAlign: 'center',
    padding: '16px',
  },
});

export default TagPage;
