import { StyleSheet, css } from 'aphrodite';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import _ from 'lodash';
import { Chip } from '@mui/material';

const filter = createFilterOptions<string>();

interface ImageTagInputProps {
  tags: string[];
  setTags: (newTags: string[]) => void;
}

const ImageTagInput = ({ tags, setTags }: ImageTagInputProps) => {
  return (
    <div className={css(styles.container)}>
      <Autocomplete
        onChange={(event, newValue) => {
          newValue &&
            !_.includes(tags, newValue) &&
            setTags([newValue, ...tags]);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option);
          if (inputValue !== '' && !isExisting) {
            filtered.unshift(inputValue);
          }

          return filtered;
        }}
        clearOnEscape
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        // TODO: Change these options
        options={topTags}
        // This translates options above to values
        // getOptionLabel={(option) => {
        //   // Value selected with enter, right from the input
        //   if (typeof option === 'string') {
        //     return option;
        //   }
        //   // Add "xxx" option created dynamically
        //   if (option.inputValue) {
        //     return option.inputValue;
        //   }
        //   // Regular option
        //   return option.title;
        // }}
        renderOption={(props, option) => <li {...props}>{option}</li>}
        sx={{ width: '100%' }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Tag your image" />
        )}
      />
      <div className={css(styles.chips)}>
        {_.map(tags, (tag, idx) => (
          <Chip
            label={tag}
            onDelete={() => setTags(_.filter(tags, (t) => t !== tag))}
            key={idx}
          />
        ))}
      </div>
    </div>
  );
};

const topTags: readonly string[] = ['tag1', 'tag2', 'tag3'];

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  chips: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '8px',
  },
});

export default ImageTagInput;
