import React from 'react';

const redirectUri = process.env.REACT_APP_REDIRECT_URI;

const LoginPage = () => {
  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const loginUrl = `https://promptr.auth.us-west-2.amazoncognito.com/login?client_id=${clientId}&response_type=token&scope=email+openid&redirect_uri=${redirectUri}`;
  window.location.replace(loginUrl);

  return <></>;
};

export default LoginPage;
