import { Outlet } from 'react-router-dom';
import { UserDataHook } from '../../core/hooks/useUserData';

const UserPage = (props: { userDataHook: UserDataHook }) => {
  return (
    <div>
      <span>Imagine a user page here!</span>
      {/* This Outlet is used to render the image popup when in the subroute */}
      <Outlet />
    </div>
  );
};

export default UserPage;
