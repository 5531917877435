import { StyleSheet, css } from 'aphrodite';

import { Card, CardContent, CardMedia, Chip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import UserLine from './UserLine';

export interface ImageCardProps {
  // NOTE: This matches what is returned from the database as closely as possible
  //   as this will be infinite scrolled, so we want this to be optimized
  id: string;
  //   If title is undefined, will default to the prompt truncated
  title?: string;
  prompt: string;
  s3Url: string;
  creator: {
    username: string;
    profilePicS3Url: string;
  };
  tags: Array<{ name: string }>;
  commentsAggregate: {
    count: number;
  };
  likedByAggregate: {
    count: number;
  };
  model?: {
    name: string;
  };
  onClick?: (imageId: string) => void;
}

const ImageCard = ({
  id,
  //   If title is undefined, will default to the prompt
  title,
  prompt,
  s3Url,
  creator: { username, profilePicS3Url },
  tags,
  commentsAggregate,
  likedByAggregate,
  model,
  onClick,
}: ImageCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div>
      <Card
        sx={{ maxWidth: 256 }}
        className={css(styles.fullCard)}
        onClick={
          onClick
            ? () => onClick(id)
            : () =>
                navigate(
                  location.pathname.endsWith('/')
                    ? `${location.pathname}${id}`
                    : `${location.pathname}/${id}`
                )
        }
      >
        {/* TODO: Overlay a like button */}
        <CardMedia
          component="img"
          image={`${s3Url}`}
          alt={`${username}'s art`}
        />
        <div className={css(styles.cardContent)}>
          <div className={css(styles.titleLine)}>{title ?? prompt}</div>
          <UserLine username={username} profilePicS3Url={profilePicS3Url} />
          {/* TODO: Num likes and comments with icons */}
          <div className={css(styles.bottomContainer)}>
            <Chip label={model?.name ?? 'SD'} />
            <span className={css(styles.likesAndComments)}>
              likes & comments
            </span>
          </div>
        </div>
      </Card>
    </div>
  );
};

const styles = StyleSheet.create({
  fullCard: {
    cursor: 'pointer',
    transition: 'opacity 0.3s',
    ':hover': {
      opacity: 0.75,
    },
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: '6px',
  },
  cardContent: {
    margin: '8px',
  },
  titleLine: {
    // No wrap
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    fontWeight: 'bold',
    fontSize: '18px',
    paddingBottom: '6px',
  },
  usernameLine: {
    // No wrap
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  userProfileButton: {
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'box-shadow 0.3s',
    ':hover': {
      boxShadow: '0 2px 4px rgba(0,0,0,0.18)',
    },
    marginRight: '6px',
  },
  userProfilePic: {
    width: '22px',
    height: '22px',
    borderRadius: '11px',
    cursor: 'pointer',
  },
  username: {
    textDecoration: 'underline',
    fontSize: '14px',
  },
  likesAndComments: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '12px',
  },
});

export default ImageCard;
