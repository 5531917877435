import { StyleSheet, css } from 'aphrodite';
import { UserDataHook } from '../hooks/useUserData';
import { Link, useNavigate } from 'react-router-dom';

const NavBar = ({
  userDataHook: { isLoggedIn, userInfo },
}: {
  userDataHook: UserDataHook;
}) => {
  const navigate = useNavigate();

  return (
    <>
      <nav className={css(styles.navBar)}>
        <span className={css(styles.navBarContent)}>
          <Link className={css(styles.navLink)} to="/">
            <img src="robot.svg" alt="robot" className={css(styles.navLogo)} />
            <span className={css(styles.navLogoText)}>Promptr</span>
          </Link>
          <div className={css(styles.userProfileButtonAndLinksContainer)}>
            <Link to="/" className={css(styles.pageLink)}>
              Explore
            </Link>
            <Link to="/create" className={css(styles.pageLink)}>
              Create
            </Link>
            {/* Render login button if not logged in and profile pic otherwise */}
            {isLoggedIn && userInfo ? (
              <button
                className={css(styles.userProfileButton)}
                // TODO: Make a dropdown menu here instead with options to go to settings or user page
                onClick={() => navigate(`/user/${userInfo?.username}`)}
              >
                <img
                  className={css(styles.userProfilePic)}
                  src={userInfo?.profilePicUrl}
                  alt="profile_pic"
                />
              </button>
            ) : (
              <button
                className={css(styles.loginButton)}
                onClick={() => navigate('/login')}
              >
                Login
              </button>
            )}
          </div>
        </span>
      </nav>
    </>
  );
};

const styles = StyleSheet.create({
  navBar: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '1px solid rgb(235, 235, 235)',
  },
  navBarContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  navLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    padding: '12px',
    paddingLeft: '50px',
  },
  navLogo: {
    width: '50px',
  },
  navLogoText: {
    fontFamily: 'Major Mono Display',
    fontSize: '20px',
    color: 'rgb(78, 82, 83)',
  },
  userProfileButtonAndLinksContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: '50px',
  },
  pageLink: {
    fontSize: '18px',
    paddingRight: '56px',
    color: 'black',
    textDecoration: 'none',
  },
  userProfileButton: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',
    ':hover': {
      boxShadow: '0 2px 4px rgba(0,0,0,0.18)',
    },
  },
  userProfilePic: {
    width: '38px',
    height: '38px',
    borderRadius: '19px',
  },
  loginButton: {
    width: '75px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    border: '1px solid rgb(220, 220, 220)',
    borderRadius: '25px',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',
    ':hover': {
      boxShadow: '0 2px 4px rgba(0,0,0,0.18)',
    },
    textDecoration: 'none',
    fontSize: '16px',
  },
  userProfileMenuIcon: {
    width: '20px',
    height: '20px',
    paddingLeft: '5px',
  },
  userProfileIcon: {
    width: '30px',
    height: '30px',
    color: 'gray',
  },
});

export default NavBar;
