import { StyleSheet, css } from 'aphrodite';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { publicPost } from '../utils/apiUtils';
import ImageCard, { ImageCardProps } from './ImageCard';
import { useInView } from 'react-cool-inview';

const IMAGES_PER_FETCH = 60;

interface ImageGalleryProps {
  // This is used to do the pagination / infinite scroll
  // What to sort the return images by
  sortedBy: 'mostLiked' | 'mostCommented' | 'newest';
  // Optionally what time range in hours to get images for. If undefined, will be all time
  time?: number;
  // Tag to get images for, if undefined will be all tags
  tag?: string;
  // User to get images for, if undefined will be all users
  userId?: string;
  // Optional list of props to prepend to the list of imageProps to allow us to add to this live without
  // having to do a full rerender and server query
  initialImageProps?: ImageCardProps[];
  // Override for the onClick of the child ImageCards
  onClickOverride?: (imageId: string) => void;
}

const ImageGallery = ({
  sortedBy,
  time,
  tag,
  userId,
  initialImageProps,
  onClickOverride,
}: ImageGalleryProps) => {
  const [imageProps, setImageProps] = useState<ImageCardProps[]>([]);
  // Use this to stop querying when we run out of images
  const [totalNumImages, setTotalNumImages] = useState<number>();

  const fullImageProps = useMemo(
    () =>
      initialImageProps ? [...initialImageProps, ...imageProps] : imageProps,
    [imageProps, initialImageProps]
  );

  const loadMoreImages = () => {
    publicPost('images/', {
      sortedBy,
      time,
      tag,
      userId,
      numImages: IMAGES_PER_FETCH,
      offset: imageProps.length,
    }).then((res) => {
      setTotalNumImages(res.data.totalImages);
      setImageProps((currImageProps) => [
        ...currImageProps,
        ...res.data.images,
      ]);
    });
  };

  // Load the first batch of images on mount
  useEffect(() => {
    loadMoreImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]); // Only grab the first batch of images here

  // Controls rendering only in view images, plus triggering the fetch for infinite scroll
  const { observe } = useInView({
    // For better UX, we can grow the root margin so the data will be loaded earlier
    // rootMargin: '50px 0px',
    // When the last item comes to the viewport
    onEnter: ({ unobserve }) => {
      // If there's no more to load...
      if (totalNumImages && totalNumImages < imageProps.length) {
        return;
      }

      // Pause observe when loading data
      unobserve();

      // Load more data
      loadMoreImages();
    },
  });

  return (
    <div className={css(styles.galleryContainer)}>
      {_.map(fullImageProps, (props, idx) => (
        <div
          className={css(styles.imageCardContainer)}
          ref={idx === fullImageProps.length - 1 ? observe : null}
          key={idx}
        >
          <ImageCard {...props} onClick={onClickOverride} />
        </div>
      ))}
    </div>
  );
};

const styles = StyleSheet.create({
  galleryContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  imageCardContainer: {
    padding: '16px',
  },
});

export default ImageGallery;
