import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import TextField from '@mui/material/TextField';

interface PromptTextBoxProps {
  text?: string;
  onChangeText: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
}

const PromptTextBox: React.FC<PromptTextBoxProps> = ({
  text,
  onChangeText,
  isLoading,
}) => {
  return (
    <TextField
      className={css(styles.promptTextBox)}
      placeholder="Prompt our AI here..."
      value={text}
      onChange={(e) => onChangeText(e.target.value)}
      multiline={true}
      minRows={3}
      maxRows={5}
      InputProps={{
        readOnly: isLoading,
      }}
    />
  );
};

const styles = StyleSheet.create({
  promptTextBox: {
    width: '100%',
  },
});

export default PromptTextBox;
